/**
 * External imports
 */
import { cloneDeep, findIndex } from "lodash";

/**
 * Imports hooks
 */
import { useUserUtils, useFilterModelsUtils } from "..";

/**
 * Imports types
 */
import {
  SocketEvent,
  WorkOrder,
  FilterModel,
  GenericFilterItem,
} from "../../types";

/**
 * Provides utility functions for the state of work overview
 */
export const useWorkOverviewUtils = () => {
  /**
   * Gets user utils
   */
  const { getUserOrganization, getDefaultWorkOrderType } = useUserUtils();

  /**
   * Gets the filter model utility hook
   */
  const { createFilter } = useFilterModelsUtils();

  /**
   * Returns the default filters
   */
  const defaultStatusFilters = [
    {
      labelKey: "New",
      value: "new",
      active: true,
      variant: "secondary",
      textColor: "secondary.120",
      textColorActive: "secondary.60",
    },
    {
      labelKey: "InProgress",
      value: "in-progress",
      active: true,
      variant: "info",
      textColor: "info.120",
      textColorActive: "info.60",
    },
    {
      labelKey: "Scheduled",
      value: "on-hold",
      active: true,
      variant: "scheduled",
      textColor: "scheduled.120",
      textColorActive: "scheduled.60",
    },
    // {
    //   labelKey: "Closed",
    //   value: "closed",
    //   active: true,
    //   variant: "error",
    //   textColor: "error.120",
    //   textColorActive: "error.60"
    // },
    {
      labelKey: "Done",
      value: "done",
      active: true,
      variant: "success",
      textColor: "success.120",
      textColorActive: "success.60",
    },
  ] as GenericFilterItem[];

  /**
   * Handles updating the work orders to show updates from a socket event
   */
  const applyEventToWorkOrders = (
    workOrders: WorkOrder[],
    payload: SocketEvent<any>,
  ) => {
    const clone = cloneDeep(workOrders);
    const { model, modelName, event } = payload;
    const index = findIndex(clone, { id: model.id });

    if (event === "CREATED" && index === -1) {
      clone.unshift({
        ...model,
        tyreService: { tyreHeight: "", tyreWidth: "", tyreRim: "" },
        status: "new",
        event: {
          type: modelName,
          createdAt: model.createdAt,
          updatedAt: model.updatedAt,
        },
      } as WorkOrder);
    }

    if (event === "UPDATED") {
      if (index > -1) {
        clone[index] = {
          ...clone[index],
          ...model,
          paymentTypeId: model.paymentType?.id || clone[index]?.paymentTypeId,
          updatedAt: clone[index].updatedAt,
          event: {
            type: modelName,
            createdAt: model.createdAt,
            updatedAt: model.updatedAt,
          },
        } as WorkOrder;
      }
    }

    if (event === "DELETED" && index > -1) {
      clone.splice(index, 1);
    }

    return clone;
  };

  /**
   * Returns the default filter models
   */
  const getDefaultFilterModels = () => {
    /**
     * Initializes the filter models
     */
    const models: FilterModel[] = [];

    /**
     * Gets the user's organization
     */
    const organization = getUserOrganization();

    /**
     * Gets the default work order type
     */
    const workOrderType = getDefaultWorkOrderType();

    /**
     * Creates the filter models
     */
    if (organization && workOrderType) {
      /**
       * Defines the organization filter model
       */
      const organizationFilterModel = createFilter({
        field: "organization_id",
        selected: organization.id,
        type: "dropdown",
      });

      /**
       * Defines the work order type filter model
       */
      const workOrderTypeFilterModel = createFilter({
        field: "work_order_type_id",
        selected: workOrderType.id,
        type: "dropdown",
      });

      models.push(organizationFilterModel);
      models.push(workOrderTypeFilterModel);

      return models;
    }

    return models;
  };

  /**
   * Handles updating the provided work order
   */
  const updateWorkOrder = (workOrders: WorkOrder[], update: WorkOrder) => {
    const index = findIndex(workOrders, { id: update.id });
    const clonedWorkOrders = cloneDeep(workOrders);
    clonedWorkOrders[index] = update;

    return clonedWorkOrders;
  };

  return {
    defaultStatusFilters,
    getDefaultFilterModels,
    applyEventToWorkOrders,
    updateWorkOrder,
  };
};
