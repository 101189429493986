import { useState, useEffect } from "react";

/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import { useApi, useActions, useSearchUtils } from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { UserOrganization } from "../../types";
import { GetUserOrganizationsOnSuccess, RequestOnError } from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
export const OrganizationsProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the organizations
   */
  const [organizations, setOrganizations] = useState<UserOrganization[]>([]);

  /**
   * Initializes the search value
   */
  const [searchValue, setSearchValue] = useState("");

  /**
   * Gets the redux actions
   */
  const { dispatchMessage, updateAccountOrganizations } = useActions();

  /**
   * Gets search utils
   */
  const { localSearch } = useSearchUtils();

  /**
   * Gets the api calls
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = () => {
    setLoading(false);
    dispatchMessage({
      message: "Failed to fetch organizations.",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles getting the organizations
   */
  const requestOrganizations = async () => {
    setLoading(true);

    /**
     * Defines the api call success callback
     */
    const onSuccess: GetUserOrganizationsOnSuccess = ({ data }) => {
      setLoading(false);
      setOrganizations(data);
    };

    await apiCalls.getUserOrganizations(onSuccess, onRequestError);
  };

  /**
   * Handles the search submit
   */
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  /**
   * Handles filtering the table results
   */
  const filterTableResult = (organizations: UserOrganization[]) => {
    let result = cloneDeep(organizations);

    result = localSearch({
      linkKey: "id",
      source: result,
      searchPool: organizations,
      searchValue,
    });

    return result;
  };

  /**
   * Handles updating the state in the local storage / local cache and global state
   */
  const syncStateWithCache = () => {
    // updateAccountOrganizations(organizations);
  };

  /**
   * Initializes the state
   */
  useEffect(() => {
    requestOrganizations();
    // eslint-disable-next-line
  }, []);

  /**
   * Stores the car types data in cache and storage when unmounting
   */
  useEffect(() => {
    window.addEventListener("beforeunload", syncStateWithCache);

    return () => {
      syncStateWithCache();
      window.removeEventListener("beforeunload", syncStateWithCache);
    };
    // eslint-disable-next-line
  }, [organizations]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    organizations,
    setOrganizations,
    handleSearch,
    filterTableResult,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
