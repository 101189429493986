import { useState, useEffect } from "react";

/**
 * External imports
 */
import { cloneDeep } from "lodash";

/**
 * Imports hooks
 */
import { useApi, useActions, useSearchUtils } from "..";

/**
 * Imports the context
 */
import { context, ProviderValues } from "./Context";

/**
 * Imports types
 */
import { PaymentType } from "../../types";
import { GetPaymentTypesOnSuccess, RequestOnError } from "../useApi";

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */

export const PaymentTypesProvider: React.FC = (props) => {
  const { children } = props;

  /**
   * Gets the Provider from the context
   */
  const { Provider } = context;

  /**
   * Gets the redux axtions
   */
  const { dispatchMessage, updateAccountPaymentTypes } = useActions();

  /**
   * Gets search utils
   */
  const { localSearch } = useSearchUtils();

  /**
   * Gets the api client
   */
  const { apiCalls } = useApi({ withCredentials: true });

  /**
   * Gets the api calls
   */
  const { getPaymentTypes } = apiCalls;

  /**
   * Initializes the loading
   */
  const [loading, setLoading] = useState(false);

  /**
   * Initializes the payment types
   */
  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);

  /**
   * Initializes the search value
   */
  const [searchValue, setSearchValue] = useState("");

  /**
   * Defines the api call error callback
   */
  const onRequestError: RequestOnError = () => {
    setLoading(false);

    dispatchMessage({
      message: "Failed to fetch payment methods.",
      severity: "error",
      autoClose: 10000,
    });
  };

  /**
   * Handles getting the payment types
   */
  const fetchPaymentTypes = async () => {
    setLoading(true);

    /**
     * Defines the api call success callback
     */
    const onSuccess: GetPaymentTypesOnSuccess = ({ data }) => {
      setLoading(false);
      setPaymentTypes(data);
    };

    await getPaymentTypes(onSuccess, onRequestError);
  };

  /**
   * Handles the search submit
   */
  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  /**
   * Handles filtering the table results
   */
  const filterTableResult = (paymentTypes: PaymentType[]) => {
    let result = cloneDeep(paymentTypes);

    result = localSearch({
      linkKey: "id",
      source: result,
      searchPool: paymentTypes,
      searchValue,
    });

    return result;
  };

  /**
   * Handles updating the state in the local storage / local cache and global state
   */
  const syncStateWithCache = () => {
    // updateAccountPaymentTypes(paymentTypes);
  };

  /**
   * Initializes the state
   */
  useEffect(() => {
    fetchPaymentTypes();
    // eslint-disable-next-line
  }, []);

  /**
   * Stores the payment types data in cache and storage when unmounting
   */
  useEffect(() => {
    window.addEventListener("beforeunload", syncStateWithCache);

    return () => {
      syncStateWithCache();
      window.removeEventListener("beforeunload", syncStateWithCache);
    };
    // eslint-disable-next-line
  }, [paymentTypes]);

  /**
   * Defines the provider value
   * These values will be available to any children component that calls the hook
   */
  const providerValue: ProviderValues = {
    loading,
    handleSearch,
    paymentTypes,
    setPaymentTypes,
    filterTableResult,
  };

  return <Provider value={providerValue}>{children}</Provider>;
};
